import React from "react"
import productselone from "../images/product-sel-product-one.svg"
import productseltwo from "../images/product-sel-product-two.svg"
import productselthree from "../images/product-sel-product-three.svg"
import { Link } from "gatsby"

const ProductBlock = () => (
  <div className="product-sel-block">
    <div className="product-box product-one">
      <img src={productselone} alt="Reviews" />
      <h4>Reviews</h4>
      <p>Build years worth of reviews in just a few months</p>
      <Link to="/reviews">LEARN MORE</Link>
    </div>

    <div className="product-box product-two">
      <img src={productseltwo} alt="Referrals" />
      <h4>Referrals</h4>
      <p>Let your customers refer your business to friends and family</p>
      <Link to="/referrals">LEARN MORE</Link>
    </div>

    <div className="product-box product-three">
      <img src={productselthree} alt="Inbox" />
      <h4>Inbox</h4>
      <p>
        Interact more over text, and less over the phone. It’s the way customers
        prefer.
      </p>
      <Link to="/inbox">LEARN MORE</Link>
    </div>
  </div>
)

export default ProductBlock
