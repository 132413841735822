import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import heroimg from "../images/hero-img.svg"
import Productblock from "../components/Productblock"
import solutionsecsmb from "../images/solution-sec-smb.png"
import solutionsecenterprise from "../images/solution-sec-enterprise.svg"
import Maincta from "../components/Maincta"
import Finalctablock from "../components/Finalctablock"
import heroimgmobile from "../css/images/heroimgmobile.svg"

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>InsideHero | Marketing Platform, Be the #1 choice</title>
      <meta
        name="description"
        content="InsideHero helps your local business get new reviews, referrals and makes it easy for customers to buy from you"
      />
      <meta
        property="og:title"
        content="InsideHero | Marketing for Local Business"
      />
    </Helmet>

    <div className="grad"></div>
    <div className="container">
      <section className="hero">
        <div className="herotxt">
          <h1>The unfair advantage for your local business</h1>
          <p>
            Win more leads, earn repeat customers - InsideHero helps collect
            reviews, referrals, and communicate with customers, all via text.
          </p>
          <a href="">
            <Maincta />
          </a>
        </div>

        <img src={heroimg} className="heroimg" alt="" />
        <img src={heroimgmobile} className="heroimg-mobile" alt="" />
      </section>
    </div>

    <div className="container">
      <section className="lp-product-section">
        <div className="lp-product-section-desc">
          <h2>Growing your business made easy</h2>
          <p>
            InsideHero is a Marketing Platform helping your business be found
            and chosen by new customers, be connected with your existing
            customers, and deliver the best customer experience. It’s lasting
            growth for the long term, unlike running paid adverts.
          </p>
        </div>

        <Productblock />
      </section>
    </div>

    <div className="cont">
      <div className="InsideHero-stripes stripes-right-top"></div>
      <div className="InsideHero-stripes stripes-left-top"></div>
    </div>

    {/*}
<div className="lp-solution-section-bg">

    <div className="container">
        <section className="lp-solution-section">

            <div className="solution-box solution-sec-smb">

                <img src={solutionsecsmb} alt=""/>
            
                <p className="InsideHerofor">InsideHero for</p>
                <h3>Small Business</h3>
                <p  className="solution-desc">Get more reviews. Top the search results. Collect referrals. Dominate local competitors. Grow your business.</p>

                <a href=""><button  className="btn btn-sol">LEARN MORE</button></a>


            </div>


            <div className="solution-box solution-sec-enterprise">
                <img src={solutionsecenterprise} alt=""/>
                <p className="InsideHerofor">InsideHero for</p>
                <h3>Enterprise</h3>
                <p className="solution-desc">Increase revenue and operations at scale. Interact with customers at every touchpoint be it 10 or 10,000 locations. </p>

                <a href=""><button className="btn btn-sol">LEARN MORE</button></a>

            </div>



        </section>
    </div>
</div> 

*/}

    <Finalctablock />

    <div className="cont">
      <div className="InsideHero-stripes stripes-right-top footer-stripes-right"></div>
      <div className="InsideHero-stripes stripes-left-top footer-stripes-left"></div>
    </div>
  </Layout>
)

export default IndexPage
